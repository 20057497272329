import { type loader as rootLoader } from '~/root.tsx';
import { useRouteLoaderDataTyped } from './misc.ts';

export function useEnv() {
	const data = useRouteLoaderDataTyped<typeof rootLoader>('root');
	if (!data?.ENV) {
		throw new Error('No ENV found in root loader');
	}
	return data.ENV;
}
